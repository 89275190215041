var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card", staticStyle: { "margin-bottom": "35px" } },
    [
      _c("section", { staticClass: "notification" }, [
        _vm.queues.length > 0 || _vm.busy
          ? _c(
              "h3",
              {
                staticClass:
                  "title is-7 has-text-weight-semibold has-text-success",
              },
              [_vm._v(" Processing ")]
            )
          : _vm._e(),
        _vm.currentAction
          ? _c("a", [
              _c("div", { staticClass: "control my-2" }, [
                _c("div", { staticClass: "tags has-addons" }, [
                  _c("span", { staticClass: "tag is-small" }, [
                    _vm._v("Processing"),
                  ]),
                  _c(
                    "span",
                    { staticClass: "tag is-light is-small is-warning" },
                    [_vm._v(_vm._s(_vm.actions[_vm.currentAction]))]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.queues.length > 0 || _vm.busy
          ? _c(
              "div",
              _vm._l(_vm.queues, function (q, idx) {
                return _c("div", { key: idx }, [
                  _c("div", { staticClass: "control" }, [
                    _c("div", { staticClass: "tags is-small has-addons" }, [
                      _c("div", { staticClass: "tag is-small" }, [
                        q.action !== "UPDATE_POSTAL_CODE"
                          ? _c("div", {
                              staticClass: "delete is-small",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeQueue(idx)
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                      _c(
                        "p",
                        {
                          staticClass:
                            "tag is-small is-size-7 is-small has-text-success",
                        },
                        [_vm._v(_vm._s(_vm.actions[q.action]))]
                      ),
                    ]),
                  ]),
                ])
              }),
              0
            )
          : _c("div", [_c("p", [_vm._v("Nothing to process!")])]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }