<template>
  <div class="card" style="margin-bottom: 35px">
    <section class="notification">
      <h3
        class="title is-7 has-text-weight-semibold has-text-success"
        v-if="queues.length > 0 || busy"
      >
        Processing
      </h3>
      <a v-if="currentAction">
        <div class="control my-2">
          <div class="tags has-addons">
            <span class="tag is-small">Processing</span>
            <span class="tag is-light is-small is-warning">{{
              actions[currentAction]
            }}</span>
          </div>
        </div>
      </a>
      <div v-if="queues.length > 0 || busy">
        <div v-for="(q, idx) in queues" :key="idx">
          <div class="control">
            <div class="tags is-small has-addons">
              <div class="tag is-small">
              <div
                v-if="q.action !== 'UPDATE_POSTAL_CODE'"
                @click.prevent="removeQueue(idx)"
                class="delete is-small"
              >
              </div>
              </div>
              <p class="tag is-small is-size-7 is-small has-text-success">{{ actions[q.action] }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p>Nothing to process!</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: ["queue", "showQueuePopUp", "currentAction", "busy"],
  emits: ["closeModal", "removeActionInQueue"],
  data() {
    return {
      undoLoading: false,
      /* TODO: standardize */
      actions: {
        INSERT_NEW_SHAPE: "Inserting New Zone",
        UPDATE_POSTAL_CODE: "Updating Postal Code",
        DELETE_SHAPE: "Delete Zone",
        INSERT_NEW_SHAPE_FROM_EDIT_OVERLAPPING: "Inserting New Zone",
        EDIT_COORDINATES: "Updating Zone",
        PROCESS_EDITING_ADD: "Editing Zone",
        PROCESS_EDITING_REMOVE: "Editing Zone",
        EDIT_MULTIPLE_ZONE_FSA: "Editing Zone",
      },
    };
  },
  computed: {
    queues() {
      return this.queue;
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    removeQueue(id) {
      this.$emit("removeActionInQueue", id);
    },
  },
};
</script>
